import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  downloadCSV,
  FunctionField,
} from "react-admin"
import jsonExport from "jsonexport/dist"

import DefaultActions from "./DefaultActions"
import DefaultPagination from "./DefaultPagination"
import React from "react"
import TimestampField from "./TimestampField"

function ClaimRejectFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {

  return (
    <Filter {...props}>
      <TextInput label='Filter by claim id' source='id' alwaysOn />
      <TextInput label='Filter by email' source='userEmail' alwaysOn />
    </Filter>
  )

}

function exporter (claims: any) {
  jsonExport(claims, {
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, "claims-reject")
  })
}

export function ClaimRejectList ({ ...props }) {
  return (
    <List
      {...props}
      filter={{
        type: "rejects",
      }}
      filters={<ClaimRejectFilters />}
      actions={<DefaultActions />}
      sort={{ field: "createdTime", order: "DESC" }}
      perPage={25}
      pagination={<DefaultPagination />}
      exporter={exporter}
    >
      <Datagrid rowClick='show'>
        <TextField label='Claim ID' source='id' sortable={false} />
        <TextField label='User ID' source='userId' sortable={false} />
        <TextField source='userEmail' sortable={false} />
        <TextField
          source='phoneNumber'
          sortable={false}
          style={{ whiteSpace: "nowrap" }}
        />
        <FunctionField
          source="utmParams"
          label='Utm Source'
          render={record => {
            if (!record?.utmParams) {
              return ""
            }

            const utmSource = record?.utmParams.find(
              (utm: { source: string; value: any; }) => 
                utm.source === "utm_source",
            )

            return utmSource?.value
          }}
        />
        <TimestampField
          source='createdTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}
