import path from "path"
import { useNotify, useRefresh, useDataProvider } from "react-admin"
import moment from "moment"
import {
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

import { storage } from "../../firebase"
import useFiles from "../../hooks/useFiles"
import UploadFileInput from "../../lib/components/UploadFileInput"
import getHttpsCallable from "../../utils/getHttpsCallable"
import React from "react"


const uploadFile = getHttpsCallable("admin-cases-uploadFile")


function subdirToTargetFileName (
  subdir: string,
  extname: string,
): string | undefined {
  const mapping: Record<string, string> = {
    ocTransactionList: `transactionslist${extname}`,
    ocCalculatedLossSheet: `calculatedlosssheet${extname}`,
    ocPoaSignature: `signature${extname}`,
    idCard: `idCard${extname}`,
    idCardMerged: `ID-Card-Merged${extname}`,
  }

  return mapping[subdir]
}


const storageRef = storage.ref()

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    color: red[500],
  },
}))


interface CheckUploadedFileParams {
  fileName: string
}


function FileTable (
  { claimId, files, lawFirm, lawFirmCaseId, subdir }: {
    claimId: string
    files: ClaimFile[]
    lawFirm: string
    lawFirmCaseId?: string
    subdir: string
  },
) {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const formatString = "YYYY-MM-DD\u{00A0}\u{00A0}HH:mm:ss\u{00A0}\u{00A0}"

  async function confirmDelete (path: string) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await storageRef.child(path).delete()
        notify("File was successfully deleted", "success")
        // TODO: Also delete document from Firestore
        refresh()
      }
      catch (error: any) {
        notify(error?.message, "warning")
      }
    }
  }

  if (!files || files.length === 0) {
    return <br/>
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Transmit to Law Firm</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map(file => {
            return (
              <TableRow key={file.downloadURL}>
                <TableCell component="th" scope="row">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={file.downloadURL}
                  > {file.name}</Link>
                </TableCell>
                <TableCell>
                  {moment(file.meta?.timeCreated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  {moment(file.meta?.updated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  {lawFirmCaseId && subdir !== "idCard" &&
                    <Button
                      variant="outlined"
                      onClick={async () => {
                        console.info(
                          `Transmit file "${file.name}" to ${lawFirm}`,
                        )
                        try {
                          await uploadFile({
                            filePath: file.meta?.fullPath,
                            targetFileName: subdirToTargetFileName(
                              subdir,
                              path.extname(file.meta?.fullPath),
                            ),
                            lawFirm,
                            internalId: lawFirmCaseId,
                            claimId,
                            shouldUpdateCase: false,
                          })

                          notify(
                            `Transmitted file "${file.meta.name}" 
                            to ${lawFirm}`,
                            "success",
                          )
                        }
                        catch (error: any) {
                          notify(error?.message, "warning")
                        }
                      }}
                    >
                      <SendIcon/>
                      &nbsp;
                      <small>Transmit to {lawFirm}</small>
                    </Button>
                  }
                  {!lawFirmCaseId &&
                    <small>
                      Claim must be transmitted<br/>
                      to law firm first
                    </small>
                  }
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={event => {
                      event.preventDefault()
                      confirmDelete(file.meta?.fullPath)
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}


export default function OnlineCasinoClaimFiles (
  props: {
    record?: {
      userId: string
      id: string
      lawFirm: string
      lawFirmCaseId: string
      customFieldValues: any
    },
  },
) {
  const userId = props?.record?.userId
  const customFieldValues = props?.record?.customFieldValues
  const notify = useNotify()

  if (!userId) {
    return <Card>
      <CardContent>userId must be specified</CardContent>
    </Card>
  }

  const claimId = props?.record?.id
  if (!claimId) {
    return <Card>
      <CardContent>claimId must be specified</CardContent>
    </Card>
  }

  const lawFirm = props?.record?.lawFirm

  const lawFirmCaseId = props?.record?.lawFirmCaseId

  const [files, loading] = useFiles({ userId, claimId })

  if (loading) {
    return <>
      <p>Loading ...</p>
      <p>(Please hard reload the website if this seems stuck)</p>
    </>
  }

  const uploadSections = [
    {
      title: "Transaction List",
      files: files.ocTransactionList,
      subdir: "ocTransactionList",
    },
    {
      title: "Calculated Loss Sheet",
      files: files.ocCalculatedLossSheet,
      subdir: "ocCalculatedLossSheet",
    },
    {
      title: "Power of Attorney Signature",
      files: files.ocPoaSignature,
      subdir: "ocPoaSignature",
    },
    {
      title: "ID card",
      files: files.idCard,
      subdir: "idCard",
    },
    {
      title: "ID card (merged)",
      files: files.idCardMerged,
      subdir: "idCardMerged",
    },
    {
      title: "Other Files",
      files: files.otherFiles,
      subdir: "otherFiles",
    },
  ]

  return (
    <div style={{ padding: 20 }}>
      {
        uploadSections.map(section =>
          <div key={section.title}>
            <Typography variant="overline">
              {section.title}
            </Typography>
            <FileTable
              claimId={claimId}
              lawFirmCaseId={lawFirmCaseId}
              files={section.files}
              lawFirm={lawFirm || ""}
              subdir={section.subdir}
            />
            <br/>

            <Grid container>
              <UploadFileInput
                buttonLabel="Upload new file"
                subdir={section.subdir}
                initialUploadedFiles={[]}
                claimId={claimId}
                userId={userId}
                customFieldValues={customFieldValues}
                onChange={event => {
                  console.info(event)
                }}
              />
              {section.subdir === "idCard" &&
                <Button
                  variant="outlined"
                  key='button'
                  style={{
                    maxHeight: "50px",
                    marginTop: "21px",
                  }}
                  onClick={event => {
                    try {
                      const apiMergeFilesInBucket =
                        getHttpsCallable("admin-mergeFilesInBucket")

                      apiMergeFilesInBucket({
                        claimId,
                        userId,
                        bucket: section.subdir,
                      }).then(() => {
                        location.reload()
                      })
                    }
                    catch (err: any) {
                      return notify(
                        err.message || "Unknown error",
                        "error",
                      )
                    }
                  }}
                >
                    Merge ID card
                </Button>
              }
            </Grid>

            <Divider style={{ margin: "20px 0" }} />
          </div>,
        )
      }
    </div>
  )
}
